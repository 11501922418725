<template>
  <div>
    <a-card title="公海客户">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="客户名称, 联系人" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.city_name" placeholder="城市" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <UserSelect v-model="searchForm.follow_up_person" placeholder="跟进人" @change="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.target_system" placeholder="目标系统" allowClear style="width: 100%" @change="search">
            <a-select-option key="WMS" value="WMS">WMS</a-select-option>
            <a-select-option key="WCS" value="WCS">WCS</a-select-option>
            <a-select-option key="ERP" value="ERP">ERP</a-select-option>
            <a-select-option key="MES" value="MES">MES</a-select-option>
            <a-select-option key="TMS" value="TMS">TMS</a-select-option>
            <a-select-option key="Others" value="Others">Others</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.intention" placeholder="客户意向" allowClear style="width: 100%" @change="search">
            <a-select-option key="low" value="low">低</a-select-option>
            <a-select-option key="medium" value="medium">中</a-select-option>
            <a-select-option key="high" value="high">高</a-select-option>
            <a-select-option key="none" value="none">无</a-select-option>
            <a-select-option key="pending" value="pending">待定</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 240px">
          <a-button-group>
            <a-button @click="openChangeFollowUpPersonModal">更换跟进人</a-button>
            <a-popconfirm title="确定转线索吗?" @confirm="markAsPrivate">
              <a-button>转线索</a-button>
            </a-popconfirm>
            <a-popconfirm v-if="isManager" title="确定删除吗?" @confirm="batchDestroy">
              <a-button type="danger" icon="delete">批量删除</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>
        <!-- <a-col :span="24" style="width: 100px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增</a-button>
        </a-col> -->

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :scroll="{ x: 1000 }"
            @change="changeTable"
          >
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @update="update" @destroy="destroy" />
            </template>
            <template slot="expandedRowRender" slot-scope="item">
              <div style="width: 800px; margin-bottom: 12px">
                <a-list
                  size="small"
                  bordered
                  :dataSource="item.lead_follow_up_record_items"
                  style="margin-top: 12px; background-color: #fff"
                >
                  <template slot="header">
                    <ListHeader :item="item" @update="list" />
                  </template>

                  <a-list-item slot="renderItem" slot-scope="record, index">
                    <a-list-item-meta>
                      <template slot="title">{{ record.method_display }} - {{ record.follow_up_date }}</template>
                      <template slot="description">
                        <div v-html="record.content" style="white-space: pre-wrap"></div>
                      </template>
                    </a-list-item-meta>
                    <ListAction :item="record" @update="list" @destroy="updateRecord(item, record)" />
                  </a-list-item>
                </a-list>
              </div>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <create-form-modal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
    <a-modal v-model="changeModalVisible" title="更换跟进人" :destroyOnClose="true" @ok="changeFollowUpPerson">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="跟进人" required>
          <UserSelect v-model="followUpPerson" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { codeToText } from "element-china-area-data";
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { leadList, leadDestroy, leadMarkAsPrivate, leadChangeFollowUpPerson } from "@/apis/data";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
    ListHeader: () => import("./ListHeader"),
    ListAction: () => import("./ListAction"),
    UserSelect: () => import("@/components/UserSelect"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined, is_public: true },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,
      selectedRowKeys: [],

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "客户名称",
          dataIndex: "client_name",
          width: 120,
        },
        {
          title: "联系人",
          dataIndex: "contact",
          width: 120,
        },
        {
          title: "联系电话",
          dataIndex: "phone",
          width: 120,
        },
        {
          title: "目标系统",
          dataIndex: "target_system",
          width: 120,
          customRender: (_, item) => (Array.isArray(item.target_system) ? item.target_system.join(", ") : item.target_system),
        },
        {
          title: "客户需求",
          dataIndex: "demand",
          width: 120,
        },
        {
          title: "下次回访日期",
          dataIndex: "next_visit_date",
          width: 120,
          sorter: true,
        },
        {
          title: "城市",
          dataIndex: "city_code",
          customRender: (value) => value.map((code) => codeToText[code]).join("/"),
          width: 120,
        },
        {
          title: "跟进人",
          dataIndex: "follow_up_person",
          width: 120,
          customRender: (_, item) => item.follow_up_person_item?.name,
        },
        {
          title: "客户意向",
          dataIndex: "intention",
          width: 120,
          customRender: (_, item) => item.intention_display,
        },
        {
          title: "客户来源",
          dataIndex: "source",
          width: 120,
        },
        {
          title: "是否签约",
          dataIndex: "signed",
          customRender: (value) => (value ? "是" : "否"),
          width: 120,
        },
        {
          title: "拜访频率",
          dataIndex: "visit_frequency",
          width: 120,
          customRender: (_, item) => item.visit_frequency_display,
        },
        {
          title: "报价记录",
          dataIndex: "quotation_record",
          width: 120,
        },
        {
          title: "备注",
          dataIndex: "remark",
          width: 120,
        },
        {
          title: "修改时间",
          dataIndex: "update_time",
          width: 180,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
      addModalVisible: false,
      changeModalVisible: false,
      followUpPerson: null,
    };
  },
  computed: {
    isManager() {
      return this.$store.state.user.isManager;
    },
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      leadList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    updateRecord(item, record) {
      item.lead_follow_up_record_items = removeItem(item.lead_follow_up_record_items, record);
      this.dataItems = replaceItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    openChangeFollowUpPersonModal() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择跟换线索");
        return;
      }

      this.followUpPerson = null;
      this.changeModalVisible = true;
    },
    changeFollowUpPerson() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning("请选择跟换线索");
        return;
      }

      if (!this.followUpPerson) {
        this.$message.warning("请选择跟进人");
        return;
      }

      leadChangeFollowUpPerson({ lead_set: this.selectedRowKeys, follow_up_person: this.followUpPerson })
        .then(() => {
          this.$message.success("更换成功");
          this.list();
        })
        .finally(() => (this.changeModalVisible = false));
    },
    async batchDestroy() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要删除的数据");
        return;
      }

      for (const key of this.selectedRowKeys) {
        await leadDestroy({ id: key });
      }
      this.$message.success("删除成功");
      this.list();
    },
    async markAsPrivate() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要转公海客户的数据");
        return;
      }

      for (const key of this.selectedRowKeys) {
        await leadMarkAsPrivate({ id: key });
      }
      this.$message.success("转线索成功");
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
